"use client";

import { useState, useEffect } from "react";
import { auth } from "../utils/data/firebase/firebase";
import { User, createUserWithEmailAndPassword, signInWithEmailAndPassword } from "firebase/auth";

const formatAuthUser = (user: User) => ({
  uid: user.uid,
  email: user.email,
});

export default function useFirebaseAuth() {
  const [authUser, setAuthUser] = useState<ReturnType<typeof formatAuthUser> | null>(null);
  const [loading, setLoading] = useState(true);

  const authStateChanged = async (authState: User | null) => {
    if (!authState) {
      setAuthUser(null);
      setLoading(false);
      return;
    }

    setLoading(true);
    var formattedUser = formatAuthUser(authState);
    setAuthUser(formattedUser);
    setLoading(false);
  };

  const clear = () => {
    setAuthUser(null);
    setLoading(true);
  };

  const signIn = async (email: string, password: string) => {
    await signInWithEmailAndPassword(auth, email, password);
  };

  const createUser = async (email: string, password: string) =>
    await createUserWithEmailAndPassword(auth, email, password);

  const signOut = async () => {
    await auth.signOut();
    clear();
  };

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged(authStateChanged);
    return () => unsubscribe();
  }, []);

  return {
    authUser,
    loading,
    signIn,
    createUser,
    signOut,
  };
}

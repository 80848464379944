"use client";

import { UserCredential } from "firebase/auth";
import React, { ReactNode, useContext } from "react";
import useFirebaseAuth from "../../hooks/useFirebaseAuth";

type AuthUser = {
  uid: string;
};

type AuthUserContextType = {
  authUser: AuthUser | null;
  loading: boolean;
  signIn: (email: string, password: string) => Promise<any>;
  createUser: (email: string, password: string) => Promise<any>;
  signOut: () => Promise<void>;
};

const authUserContext = React.createContext<AuthUserContextType>({
  authUser: null,
  loading: true,
  signIn: async (email: string, password: string) => {
    return {} as UserCredential;
  },
  createUser: async (email: string, password: string) => {
    return {} as UserCredential;
  },
  signOut: async () => {},
});

export function AuthUserProvider({ children }: { children: ReactNode }) {
  const auth = useFirebaseAuth();
  return <authUserContext.Provider value={auth}>{children}</authUserContext.Provider>;
}
export const useAuth = () => {
  return useContext(authUserContext);
};

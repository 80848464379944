"use client";

import Hamburger from "@/components/Hamburger";
import Navbar from "@/components/Navbar";
import { useAuth } from "@/context/AuthUserProvider";
import { usePathname, useRouter } from "next/navigation";
import { ReactNode, useEffect } from "react";
import { ToastContainer } from "react-toastify";

export default function ClientLayout({ children }: { children: ReactNode }) {
  const router = useRouter();
  const pathname = usePathname();
  const { authUser, loading } = useAuth();

  useEffect(() => {
    if (!loading && !authUser && pathname !== "/" && pathname !== "/login") {
      router.push("/");
    }
  }, [authUser, loading, pathname, router]);

  // Don't render anything while loading
  if (loading) {
    return null;
  }

  // If not authenticated and not on allowed pages, don't render content
  // (the useEffect will handle redirection)
  if (!authUser && pathname !== "/" && pathname !== "/login") {
    return null;
  }

  return (
    <>
      {/* Only show navigation components when user is logged in */}
      {authUser && (
        <>
          <div className="block sm:hidden">
            <Hamburger />
          </div>
          <div className="hidden sm:block">
            <Navbar />
          </div>
        </>
      )}
      <ToastContainer autoClose={2000} />
      <main className="bg-primary min-h-screen h-full text-[#ccc] w-screen">{children}</main>
    </>
  );
}
